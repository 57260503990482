import { Trans } from 'next-i18next';
import { BsCheckCircleFill } from 'react-icons/bs';
import { FaWallet } from 'react-icons/fa';
import type { FC } from 'react';
import useWeb3 from '@lib/web3/hooks/useWeb3';
import Button from '@mui/material/Button';
import type { ButtonProps } from '@mui/material/Button';

export type ConnectWalletButtonProps = Omit<ButtonProps, 'onClick' | 'children' | 'disabled'>;

/**
 * Button allowing a user to connect to his/her MetaMask wallet.
 */
const ConnectWalletButton: FC<ConnectWalletButtonProps> = (props) => {
  const { active, activate } = useWeb3();

  return active ? (
    <Button disabled startIcon={<BsCheckCircleFill />} {...props}>
      <Trans i18nKey="actions.connected-to-metamask">Connected</Trans>
    </Button>
  ) : (
    <Button onClick={activate} startIcon={<FaWallet />} {...props}>
      <Trans i18nKey="actions.connect-to-metamask">Connect to MetaMask</Trans>
    </Button>
  );
};

export default ConnectWalletButton;
