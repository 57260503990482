import type { ERC20Token } from '@lib/web3/constants/tokens';
import type { WatchAssetParameters } from '@lib/web3/eip/EIP-747';
import useWeb3 from '@lib/web3/hooks/useWeb3';

/**
 * @link https://eips.ethereum.org/EIPS/eip-747
 */
export default function useWatchAsset() {
  const { library } = useWeb3();

  return (options: ERC20Token) => {
    return library?.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options,
      } as WatchAssetParameters,
    });
  };
}
