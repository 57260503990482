import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';
import client from '@graphql/client';
import useGTM from '@hooks/useGTM';
import useImpersonate from '@hooks/useImpersonate';
import { JWT_STORAGE_KEY } from '@lib/constants';
import useWeb3 from '@lib/web3/hooks/useWeb3';
import * as Sentry from '@sentry/nextjs';

export default function useLogout() {
  const router = useRouter();
  const { stop } = useImpersonate();
  const { deactivate } = useWeb3();
  const sendToGTM = useGTM();
  const { t } = useTranslation();

  return async () => {
    deactivate();
    stop();
    localStorage.removeItem(JWT_STORAGE_KEY);
    await client.clearStore();
    Sentry.setUser(null);
    toast.success(t('auth:logout-successful', { defaultValue: 'Logout successfully' }));
    sendToGTM({ user_id: '' });
    return router.push('/auth/login');
  };
}
