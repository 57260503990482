import { useCallback, useState } from 'react';
import useUser from '@hooks/useUser';
import { KYC_TIERS } from '@lib/constants';
import useEligibility from '@lib/web3/hooks/contracts/useEligibility';
import useWeb3 from '@lib/web3/hooks/useWeb3';
import useWeb3Effect from '@lib/web3/hooks/useWeb3Effect';

export default function useWallet() {
  const [tier, setTier] = useState(-1);
  const [remaining_USD, setRemaining_USD] = useState(0);
  const { account } = useWeb3();
  const { user } = useUser();
  const eligibility = useEligibility();
  const mismatch = Boolean(account && user?.wallet?.address && account !== user?.wallet?.address);

  const refresh = useCallback(async () => {
    let limit = Infinity;
    let newTier = user?.kyc.tier ?? 0;

    if (eligibility && account) {
      const result = await eligibility.results(account);
      newTier = result.tier;
    }

    switch (newTier) {
      case 0:
        limit = 0;
        break;
      case 1:
        limit = KYC_TIERS.TIER_1.limit;
        break;
      case 2:
        limit = KYC_TIERS.TIER_2.limit;
        break;
    }

    setTier(newTier);
    setRemaining_USD(limit - (user?.investedUSD ?? 0));
  }, [account, eligibility, user]);

  useWeb3Effect(refresh);

  return {
    tier,
    mismatch,
    remaining_USD,
  };
}
