import { CHAIN_ID } from '@lib/web3/constants/chains';
import ChainId from '@lib/web3/lib/ChainId';

const mainnet = {
  DPS: '0xf192caE2e7Cd4048Bea307368015E3647c49338e',
  STC: '0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e',
  Eligibility: '0x52088e60AfB56E83cA0B6340B49F709e57973869',
  Sale: '0xe5196aB7E1639f2e683CeA8564F974f720F7d6EA',
  Aggregator: '0x0A77230d17318075983913bC2145DB16C7366156',
  cafe: '0xCAFE0e6ac3384cb18344e57710B61F26654Dd347',
};

const testnet = {
  DPS: '0xEeA08029DCcEBAe971039F4B4b2064D466baC2e5',
  STC: '0x868cB9ab1436b071c32C68f0125c54c1eF21b11d',
  Eligibility: '0x3bFB1294843B91139e66D5919102a79e6C9701ee',
  Sale: '0x721Ae05A95E1Ac7595f526ce5B0c2545fCFe6e97',
  Aggregator: '0x5498BB86BC934c8D34FDA08E81D444153d0D06aD',
  cafe: '0xCAFE0e6ac3384cb18344e57710B61F26654Dd347',
  LockingSecurity: '0x3d860145aC2f45A0cc0626419b6ac03DE5b110f9',
};

type AddressBook = typeof mainnet & Partial<typeof testnet>;

const addresses2: Record<ChainId, AddressBook> = {
  [ChainId.AVALANCHE_MAINNET]: mainnet,
  [ChainId.AVALANCHE_TESTNET]: testnet,
  [ChainId.AVALANCHE_LOCALNET]: testnet,
};

const current = addresses2[CHAIN_ID];

export const addressCafe = current.cafe;
export const addressDPS = current.DPS;
export const addressSTC = current.STC;
export const addressEligibility = current.Eligibility;
export const addressSale = current.Sale;
export const addressAggregator = current.Aggregator;
export const addressLockingSecurity = current.LockingSecurity;
