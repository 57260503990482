import classnames from 'classnames';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import Link from '@components/routing/Link';
import Tooltip from '@mui/material/Tooltip';

interface HeaderButtonProps {
  href: string;
  disabled?: boolean;
  disabledText?: string;
}

const HeaderButton: FC<HeaderButtonProps> = ({ href, disabled, disabledText, children, ...props }) => {
  const router = useRouter();
  const isActive = router.asPath === href;
  const common = 'font-black';

  if (disabled) {
    const content = <span className={classnames(common, 'text-gray-300 cursor-not-allowed')}>{children}</span>;

    if (disabledText) {
      return <Tooltip title={disabledText}>{content}</Tooltip>;
    }

    return content;
  }

  return (
    <Link
      href={href}
      className={classnames(
        common,
        isActive ? 'text-highlight underline underline-offset-8 decoration-1' : 'text-white hover:text-highlight',
      )}
      raw
      {...props}
    >
      {children}
    </Link>
  );
};

export default HeaderButton;
