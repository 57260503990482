import { Trans } from 'next-i18next';
import Image from 'next/image';
import { BsInfoCircle } from 'react-icons/bs';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import Jazzicon from 'react-jazzicon';
import type { FC } from 'react';
import { useState } from 'react';
import BlockChainExplorer from '@components/web3/BlockChainExplorer';
import { NA } from '@lib/constants';
import { CHAIN } from '@lib/web3/constants/chains';
import { DPS, STC } from '@lib/web3/constants/tokens';
import useBalances from '@lib/web3/hooks/useBalances';
import useWatchAsset from '@lib/web3/hooks/useWatchAsset';
import useWeb3 from '@lib/web3/hooks/useWeb3';
import Token from '@lib/web3/lib/Token';
import Button from '@mui/material/Button';
import avax from '@public/icons/avax.svg';
import usdc from '@public/icons/usdc.svg';
import dps from '@public/img/icon.png';
import formatAVAX from '@utils/format/formatAVAX';
import formatAccount from '@utils/format/formatAccount';
import formatDPS from '@utils/format/formatDPS';
import formatSTC from '@utils/format/formatSTC';

const MobileDropdown: FC = () => {
  const [showBalances, setShowBalances] = useState(false);

  const { active, account } = useWeb3();
  const { balance_wDPS, balance_wAVAX, balance_uSTC } = useBalances();
  const watch = useWatchAsset();

  return (
    <div className="flex flex-col justify-center md:hidden">
      {showBalances ? (
        <>
          <div className="flex flex-col bg-black bg-opacity-40 pb-4 px-3">
            {!active || !account ? (
              <span className="px-4">
                <BsInfoCircle className="mr-2" />
                <Trans i18nKey="web3.alert.connect-your-metamask-wallet">
                  Connect your MetaMask wallet to see your current balance.
                </Trans>
              </span>
            ) : (
              <>
                <div className="flex items-center justify-center rounded-full space-x-3 border-2 border-solid border-white border-opacity-70 p-2 mb-3">
                  <Jazzicon diameter={24} seed={parseInt(account.slice(2, 10), 16)} />
                  <BlockChainExplorer
                    address={account}
                    token={Token.NATIVE}
                    className="text-white font-bold translate-y-0.5"
                  >
                    {formatAccount(account, 8)}
                  </BlockChainExplorer>
                </div>
                <div className="flex items-center justify-around ">
                  <div className="flex gap-2">
                    <Image
                      src={dps}
                      alt="DPS Logo"
                      width={16}
                      height={24}
                      layout="fixed"
                      onClick={() => watch(DPS)}
                      className="cursor-pointer"
                      title="Watch DPS asset"
                    />
                    <BlockChainExplorer
                      address={account}
                      token={Token.DPS}
                      className="text-white font-bold"
                      data-test="Wallet--balance-dps"
                    >
                      {balance_wDPS ? formatDPS(balance_wDPS) : NA} {Token.DPS}
                    </BlockChainExplorer>
                  </div>

                  <div className="flex items-center gap-2">
                    <Image src={avax} alt="Avax Logo" width={24} height={24} layout="fixed" />
                    <BlockChainExplorer
                      address={account}
                      token={Token.NATIVE}
                      className="text-white font-bold"
                      data-test="Wallet--balance-native"
                    >
                      {balance_wAVAX ? formatAVAX(balance_wAVAX) : NA} {CHAIN.nativeCurrency.symbol}
                    </BlockChainExplorer>
                  </div>

                  <div className="flex items-center gap-2">
                    <Image
                      src={usdc}
                      alt={`${STC.symbol} Logo`}
                      width={24}
                      height={24}
                      layout="fixed"
                      onClick={() => watch(STC)}
                      className="cursor-pointer"
                      title={`Watch ${STC.symbol} asset`}
                    />
                    <BlockChainExplorer
                      address={account}
                      token={Token.STC}
                      className="text-white font-bold"
                      data-test="Wallet--balance-stc"
                    >
                      {balance_uSTC ? formatSTC(balance_uSTC) : NA} {STC.symbol}
                    </BlockChainExplorer>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="flex items-center">
            <div className="flex justify-center w-1/3 bg-black bg-opacity-40">
              <div className="flex justify-center w-full h-6 rounded-tr-2xl bg-background" />
            </div>
            <div className="flex justify-center w-1/3 rounded-b-xl bg-black bg-opacity-40">
              <Button
                className="p-0 bg-black bg-opacity-0 shadow-none"
                style={{ backgroundColor: 'rgba(0,0,0,0.4)' }}
                onClick={() => setShowBalances((prev) => !prev)}
              >
                <IoIosArrowUp color="white" size={24} />
              </Button>
            </div>
            <div className="flex justify-center w-1/3 bg-black bg-opacity-40">
              <div className="flex justify-center w-full h-6 rounded-tl-2xl bg-background" />
            </div>
          </div>
        </>
      ) : (
        <div className="flex items-center">
          <div className="flex justify-center w-1/3 bg-black bg-opacity-40">
            <div className="flex justify-center w-full h-6 rounded-tr-2xl bg-background" />
          </div>
          <div className="flex justify-center w-1/3 rounded-b-xl bg-black bg-opacity-40">
            <Button
              className="p-0 bg-black bg-opacity-0 shadow-none"
              color="inherit"
              onClick={() => setShowBalances((prev) => !prev)}
            >
              <IoIosArrowDown color="white" size={24} />
            </Button>
          </div>
          <div className="flex justify-center w-1/3 bg-black bg-opacity-40">
            <div className="flex justify-center w-full h-6 rounded-tl-2xl bg-background" />
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileDropdown;
