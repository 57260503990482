import type { FC, PropsWithChildren, ReactNode } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

interface LoaderProps extends PropsWithChildren<{ loading: boolean | ReactNode }> {}

const Loader: FC<LoaderProps> = ({ loading, children }) => {
  return (
    <>
      {loading ? (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
          <CircularProgress size={80} />
          {typeof loading === 'string' && <div className="mt-2">{loading}</div>}
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default Loader;
