import type { Provider } from '@ethersproject/abstract-provider';
import type { Signer } from '@ethersproject/abstract-signer';
import { STC } from '@lib/web3/constants/tokens';
import { createERC20 } from '@lib/web3/contracts/ERC20';

export const STCContract = Symbol('StableCoinContract');

export function createSTC(provider?: Signer | Provider) {
  return createERC20(STC.address, provider);
}
