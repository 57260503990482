import { useMemo } from 'react';
import type { Provider } from '@ethersproject/abstract-provider';
import type { Signer } from '@ethersproject/abstract-signer';
import type { BaseContract } from '@ethersproject/contracts';
import useWeb3 from '@lib/web3/hooks/useWeb3';
import type { ContractFactory } from '@lib/web3/types/ContractFactory';

interface ContractHook<T extends BaseContract> {
  (): T | undefined;
  (provider: Provider | Signer): T;
}

export default function createContractHook<T extends BaseContract>(factory: ContractFactory<T>): ContractHook<T> {
  // @ts-expect-error TS is just too dumb to understand that
  return (provider?: Provider | Signer) => {
    const { account, library, isChainSupported } = useWeb3();

    return useMemo(() => {
      if (provider) {
        return factory(provider);
      } else if (library && account && isChainSupported) {
        return factory(provider ?? library.getSigner(account));
      }
    }, [provider, account, isChainSupported, library]);
  };
}
