import { Trans } from 'next-i18next';
import { useRouter } from 'next/router';
import type { VFC } from 'react';
import { useCallback, useState } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import useSale from '@lib/web3/hooks/contracts/useSale';
import useJsonProvider from '@lib/web3/hooks/useJsonProvider';
import useWeb3Effect from '@lib/web3/hooks/useWeb3Effect';
import Skeleton from '@mui/material/Skeleton';
import formatDPS from '@utils/format/formatDPS';
import formatUSD from '@utils/format/formatUSD';

/**
 * Header indicating the status of the currently connected MetaMask wallet.
 */
const FundingProgress: VFC = () => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [rate, setRate] = useState(40);
  const [remaining, setRemaining] = useState(BigNumber.from(0));
  const [progress, setProgress] = useState(0); // In percent
  const sale = useSale(useJsonProvider());

  const refresh = useCallback(async () => {
    if (!sale) return;

    setLoading(true);
    const [sold, remaining, newRate] = await Promise.all([sale.sold(), sale.remaining(), sale.rate()]).finally(() =>
      setLoading(false),
    );
    const total = sold.add(remaining);

    setRate(newRate);
    setRemaining(remaining);
    setProgress(remaining.mul(100).div(total).toNumber());
  }, [sale]);

  useWeb3Effect(refresh);

  return (
    <div className="relative overflow-hidden h-8 text-xs flex bg-teal-500 w-full md:w-1/3 flex justify-end text-white">
      <Skeleton
        className="shadow-none flex flex-col whitespace-nowrap justify-center bg-teal-700 transition-all"
        variant="rectangular"
        width={`${progress}%`}
        height="100%"
      />

      <div className="absolute h-full flex items-center gap-1 right-4 text-sm lg:text-base">
        {loading ? (
          <Skeleton variant="text" width="18ch" />
        ) : (
          <span className="font-bold lg:pt-0.5" data-test="FundingProgress--remaining">
            {remaining && formatDPS(remaining)}
          </span>
        )}
        <Trans i18nKey="web3.dps-remaining-at">DPS tokens remaining at</Trans>
        <span className="font-bold lg:pt-0.5">{formatUSD(rate / 100, router.locale)}</span>
      </div>
    </div>
  );
};

export default FundingProgress;
