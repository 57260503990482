import classnames from 'classnames';
import { Trans, useTranslation } from 'next-i18next';
import { BsJustify } from 'react-icons/bs';
import { FaSignOutAlt } from 'react-icons/fa';
import type { ComponentProps, VFC } from 'react';
import { useState } from 'react';
import HeaderButton from '@components/buttons/HeaderButton';
import DebugBar from '@components/structure/DebugBar';
import HeaderAlerts from '@components/structure/HeaderAlerts';
import MobileDropdown from '@components/structure/MobileDropdown';
import Logo from '@components/ui/branding/Logo/Logo';
import ConnectWalletButton from '@components/web3/ConnectWalletButton';
import FundingProgress from '@components/web3/FundingProgress';
import WalletBalances from '@components/web3/WalletBalances';
import useLogout from '@hooks/useLogout';
import useUser from '@hooks/useUser';
import useWeb3 from '@lib/web3/hooks/useWeb3';
import Button from '@mui/material/Button';

/**
 * Main layout's header.
 * @param props
 * @param props.className {@link ElementProps.className}
 */
const Header: VFC<ComponentProps<'header'>> = ({ className, ...props }) => {
  const [showMenu, setShowMenu] = useState(false);
  const { user } = useUser();
  const logout = useLogout();
  const { active } = useWeb3();
  const { t } = useTranslation();

  return (
    <header className={className} {...props}>
      <DebugBar />

      <div className="flex items-center py-3 bg-black bg-opacity-40">
        <div className="flex flex-grow justify-center items-center">
          <div className="flex justify-between items-center w-11/12 lg:w-5/6 space-x-3">
            <FundingProgress />
            <div className="hidden md:flex gap-6">
              <WalletBalances />
            </div>
          </div>
        </div>
      </div>

      <MobileDropdown />

      <div className="flex justify-center">
        <nav className="lg:flex lg:items-center lg:flex-shrink lg:justify-between w-11/12 lg:py-8 lg:w-5/6 py-8">
          <div className="flex items-center justify-between space-x-3">
            <Logo width={192} />
            <div className="flex lg:hidden space-x-3">
              <div className="block" onClick={() => setShowMenu((prev) => !prev)}>
                <BsJustify size={32} />
              </div>
            </div>
          </div>

          <div className={classnames('lg:flex-grow lg:flex lg:w-auto', { hidden: !showMenu })}>
            <div className="text-lg flex flex-col items-center gap-2 my-2 lg:flex lg:flex-row lg:items-baseline lg:flex-grow lg:gap-7 lg:my-5 lg:ml-24">
              <HeaderButton href="/">
                <Trans i18nKey="nav.launchpad">Private Sale</Trans>
              </HeaderButton>
              <HeaderButton href="/referral">
                <Trans i18nKey="nav.referral">Referral</Trans>
              </HeaderButton>
              <HeaderButton href="/profile">
                <Trans i18nKey="nav.profile">Profile</Trans>
              </HeaderButton>
              <HeaderButton href="https://snapshot.org/#/deepsquare.eth">
                <Trans i18nKey="nav.vote">Vote</Trans>
              </HeaderButton>
              <HeaderButton href="/faq">
                <Trans i18nKey="nav.faq">FAQ</Trans>
              </HeaderButton>

              {user && user.admin && (
                <div>
                  <HeaderButton
                    href="/admin"
                    disabled={!active}
                    disabledText={t('nav.admin-disabled', {
                      defaultValue: 'You need to connect your MetaMask wallet in order to access the admin panel.',
                    })}
                  >
                    <Trans i18nKey="nav.admin">Admin</Trans>
                  </HeaderButton>
                </div>
              )}
            </div>

            <div className="flex flex-col space-y-2 lg:items-center lg:flex-row lg:space-x-4 lg:space-y-0 lg:ml-12 ">
              <ConnectWalletButton data-test="Header--connect-button" />

              <Button onClick={() => logout()} color="primary" variant="outlined" data-test="Header--logout-button">
                <FaSignOutAlt className="mr-2" />
                <Trans i18nKey="actions.sign-out">Sign out</Trans>
              </Button>
            </div>
          </div>
        </nav>
      </div>
      <HeaderAlerts />
    </header>
  );
};

export default Header;
