import { Trans } from 'next-i18next';
import type { VFC } from 'react';
import { CHAIN } from '@lib/web3/constants/chains';
import useWeb3 from '@lib/web3/hooks/useWeb3';
import isUnsupportedChainIdError from '@lib/web3/utils/isUnsupportedChainIdError';
import hex2dec from '@utils/hex2dec';

const NetworkBanner: VFC = () => {
  const { chainId, error, changeNetwork } = useWeb3();
  const chainMismatch =
    isUnsupportedChainIdError(error) || (typeof chainId === 'number' && chainId !== hex2dec(CHAIN.chainId));

  if (!chainMismatch) return null;

  return (
    <div style={{ backgroundColor: '#d32f2f' }}>
      <div className="container py-8">
        <Trans i18nKey="network-banner">
          <h2 className="mt-0">DeepSquare now runs on Avalanche Network!</h2>
          <p>
            Please switch to the{' '}
            <u className="underline cursor-pointer" onClick={changeNetwork}>
              Avalanche Network
            </u>
            .
          </p>
        </Trans>
      </div>
    </div>
  );
};
export default NetworkBanner;
