import { Trans } from 'next-i18next';
import Image from 'next/image';
import { BsInfoCircle } from 'react-icons/bs';
import BlockChainExplorer from '@components/web3/BlockChainExplorer';
import { NA } from '@lib/constants';
import { CHAIN } from '@lib/web3/constants/chains';
import { DPS, STC } from '@lib/web3/constants/tokens';
import useBalances from '@lib/web3/hooks/useBalances';
import useWatchAsset from '@lib/web3/hooks/useWatchAsset';
import useWeb3 from '@lib/web3/hooks/useWeb3';
import Token from '@lib/web3/lib/Token';
import avax from '@public/icons/avax.svg';
import usdc from '@public/icons/usdc.svg';
import dps from '@public/img/icon.png';
import formatAVAX from '@utils/format/formatAVAX';
import formatAccount from '@utils/format/formatAccount';
import formatDPS from '@utils/format/formatDPS';
import formatSTC from '@utils/format/formatSTC';

const WalletBalances = () => {
  const { active, account, error } = useWeb3();
  const { balance_wDPS, balance_wAVAX, balance_uSTC } = useBalances();
  const watch = useWatchAsset();

  if (!active || !account || error) {
    return (
      <span>
        <BsInfoCircle className="mr-2" />
        <Trans i18nKey="web3.alert.connect-your-metamask-wallet">
          Connect your MetaMask wallet to see your current balance.
        </Trans>
      </span>
    );
  }

  return (
    <div className="flex flex-col items-center lg:flex-row lg:items-baseline lg:justify-between">
      <div className="flex items-center justify-end space-x-10 font-extrabold text-sm">
        <div className="flex items-center gap-2">
          <Image
            src={dps}
            alt="DPS Logo"
            width={12}
            height={16}
            layout="fixed"
            onClick={() => watch(DPS)}
            className="cursor-pointer"
            title="Watch DPS asset"
          />
          <BlockChainExplorer
            address={account}
            token={Token.DPS}
            className="text-white"
            data-test="Wallet--balance-dps"
          >
            {balance_wDPS ? formatDPS(balance_wDPS) : NA} {Token.DPS}
          </BlockChainExplorer>{' '}
        </div>

        <div className="flex items-center gap-2">
          <Image src={avax} alt="Avax Logo" width={16} height={16} layout="fixed" />
          <BlockChainExplorer
            address={account}
            token={Token.NATIVE}
            className="text-white"
            data-test="Wallet--balance-native"
          >
            {balance_wAVAX ? formatAVAX(balance_wAVAX) : NA} {CHAIN.nativeCurrency.symbol}
          </BlockChainExplorer>
        </div>

        <div className="flex items-center gap-2">
          <Image
            src={usdc}
            alt={`${STC.symbol} Logo`}
            width={16}
            height={16}
            layout="fixed"
            onClick={() => watch(STC)}
            className="cursor-pointer"
            title={`Watch ${STC.symbol} asset`}
          />
          <BlockChainExplorer
            address={account}
            token={Token.STC}
            className="text-white"
            data-test="Wallet--balance-stc"
          >
            {balance_uSTC ? formatSTC(balance_uSTC) : NA} {STC.symbol}
          </BlockChainExplorer>
        </div>

        <div>
          <BlockChainExplorer address={account} token={Token.NATIVE} className="text-white">
            {formatAccount(account)}
          </BlockChainExplorer>
        </div>
      </div>
    </div>
  );
};

export default WalletBalances;
