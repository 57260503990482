import { useMemo } from 'react';
import { StaticJsonRpcProvider } from '@ethersproject/providers';
import type { JsonRpcProvider } from '@ethersproject/providers';
import { CHAIN } from '@lib/web3/constants/chains';

/**
 * Get an ethers {@see JsonRpcProvider} that is connected to the Avalanche Blockchain API.
 * Since it has no loaded keys, this provider can only be used for read-only operations like checking the balance of an
 * account.
 */
export default function useJsonProvider(): JsonRpcProvider {
  return useMemo(() => new StaticJsonRpcProvider(CHAIN.rpcUrls[0]), []);
}
