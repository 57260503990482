import type { BigNumberish } from '@ethersproject/bignumber';
import { BigNumber } from '@ethersproject/bignumber';

export interface FormatBigNumberOptions {
  divide?: number;
  precision?: number;
}

/**
 * Intermediate function to split a big number with comma every 3 digits.
 * @param {string} raw The number to split as an string.
 * @returns {string}
 * @example 12345678 => 12,345,678
 */
const addIntegerPartSeparator = (raw: string) => {
  if (raw.length <= 3) return raw;

  const chunks = raw.length % 3 === 0 ? [] : [raw.substr(0, raw.length % 3)];

  for (let i = raw.length % 3; i < raw.length; i += 3) {
    chunks.push(raw.substr(i, 3));
  }

  return chunks.join(',');
};

const incr = (raw: string, preserveLeadingZeros = false) => {
  const result = BigNumber.from(raw).add(1).toString();

  if (!preserveLeadingZeros) {
    return result;
  }

  // Preserve leading zeros
  return '0'.repeat(raw.match(/^0+/)?.[0]?.length ?? 0) + result;
};

/**
 * Format a big number by adding a comma every three digits.
 * @param {BigNumber} n The big number to format.
 * @param {number} divide If the big number needs to be divided.
 * @param {number} round If the big number needs to be rounded.
 * @returns {string}
 */
export default function formatBigNumber(
  n: BigNumberish,
  { divide = 0, precision = Infinity }: FormatBigNumberOptions = {},
): string {
  const raw = BigNumber.from(n).toString(); // Base 10
  let integerPart = raw.length > divide ? raw.substr(0, raw.length - divide) : '0';

  const decimalPart =
    raw.length > divide ? raw.substr(raw.length - divide, divide) : '0'.repeat(divide - raw.length) + raw;

  if (precision === 0 || divide === 0) {
    integerPart = parseInt(decimalPart.charAt(0)) >= 5 ? incr(integerPart) : integerPart;
    return addIntegerPartSeparator(integerPart);
  }

  let roundedPart = decimalPart.substr(0, precision);
  roundedPart = parseInt(decimalPart.charAt(precision)) >= 5 ? incr(roundedPart, true) : roundedPart;

  if (roundedPart.length > precision) {
    integerPart = incr(integerPart);
  }

  roundedPart = roundedPart.replace(/0+$/, ''); // Trim zeros

  return `${addIntegerPartSeparator(integerPart)}${roundedPart.length > 0 ? '.' : ''}${roundedPart}`;
}
