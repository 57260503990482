import type { Provider } from '@ethersproject/abstract-provider';
import type { Signer } from '@ethersproject/abstract-signer';
import type { BigNumber, BigNumberish } from '@ethersproject/bignumber';
import { BaseContract } from '@ethersproject/contracts';
import { Contract } from '@ethersproject/contracts';
import type { TransactionResponse } from '@ethersproject/providers';
import abi from '@lib/web3/contracts/ERC20.abi.json';

export function createERC20(address: string, provider?: Signer | Provider): ERC20 {
  return new Contract(address, abi, provider) as ERC20;
}

/**
 * OpenZeppelin implementation of the ERC contract.
 * @link https://github.com/OpenZeppelin/openzeppelin-contracts/blob/f2a311dc4a5757ee8064769a603a715b05d359b3/contracts/token/ERC20/ERC20.sol
 */
export abstract class ERC20 extends BaseContract {
  abstract name(): Promise<string>;

  abstract symbol(): Promise<string>;

  abstract decimals(): Promise<number>;

  abstract balanceOf(owner: string): Promise<BigNumber>;

  abstract allowance(owner: string, spender: string): Promise<BigNumber>;

  abstract increaseAllowance(spender: string, addedValue: BigNumberish): Promise<TransactionResponse>;

  abstract transfer(to: string, value: BigNumberish): Promise<TransactionResponse>;
}
