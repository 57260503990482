import { useCallback, useState } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { CHAIN } from '@lib/web3/constants/chains';
import useDPS from '@lib/web3/hooks/contracts/useDPS';
import useSTC from '@lib/web3/hooks/contracts/useSTC';
import useWeb3 from '@lib/web3/hooks/useWeb3';
import useWeb3Effect from '@lib/web3/hooks/useWeb3Effect';
import hex2dec from '@utils/hex2dec';

interface UseBalancesOutput {
  balance_wDPS: BigNumber;
  balance_wAVAX: BigNumber;
  balance_uSTC: BigNumber;
}

export default function useBalances(): UseBalancesOutput {
  const [balance_wDPS, setBalance_wDPS] = useState(BigNumber.from(0));
  const [balance_wAVAX, setBalance_wAVAX] = useState(BigNumber.from(0));
  const [balance_uSTC, setBalance_uSTC] = useState(BigNumber.from(0));

  const { account, library, error, chainId } = useWeb3();
  const dps = useDPS();
  const stc = useSTC();

  const updateBalances = useCallback(() => {
    if (!account || error || chainId !== hex2dec(CHAIN.chainId)) return;

    if (dps) {
      void dps.balanceOf(account).then(setBalance_wDPS);
    }

    if (library) {
      void library.getBalance(account).then(setBalance_wAVAX);
    }

    if (stc) {
      void stc.balanceOf(account).then(setBalance_uSTC);
    }
  }, [account, chainId, dps, error, library, stc]);

  useWeb3Effect(updateBalances);

  return {
    balance_wDPS,
    balance_wAVAX,
    balance_uSTC,
  };
}
