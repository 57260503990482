import { useEffect, useState } from 'react';
import type { BigNumber } from '@ethersproject/bignumber';
import useJsonProvider from '@lib/web3/hooks/useJsonProvider';

export default function useAVAXBalance(account?: string): BigNumber | undefined {
  const [balance, setBalance] = useState<BigNumber>();
  const provider = useJsonProvider();

  useEffect(() => {
    if (account) {
      void provider.getBalance(account).then(setBalance);
    }
  }, [account, provider]);

  return balance;
}
