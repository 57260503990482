import type { Provider } from '@ethersproject/abstract-provider';
import type { Signer } from '@ethersproject/abstract-signer';
import { DPS } from '@lib/web3/constants/tokens';
import { createERC20 } from '@lib/web3/contracts/ERC20';

export const DPSContract = Symbol('DPSContract');

export function createDPS(provider: Signer | Provider) {
  return createERC20(DPS.address, provider);
}
