import type { Provider } from '@ethersproject/abstract-provider';
import type { Signer } from '@ethersproject/abstract-signer';
import { BaseContract } from '@ethersproject/contracts';
import { Contract } from '@ethersproject/contracts';
import type { TransactionResponse } from '@ethersproject/providers';
import { addressEligibility } from '@lib/web3/constants/contracts';
import abi from '@lib/web3/contracts/Eligibility.abi.json';

export interface Result {
  tier: number;
  validator: string;
  transactionId: string;
}

export const EligibilityContract = Symbol('Eligibility');

export function createEligibility(signer: Provider | Signer) {
  return new Contract(addressEligibility, abi, signer) as Eligibility;
}

export abstract class Eligibility extends BaseContract {
  abstract results(account: string): Promise<Result>;

  abstract setResult(account: string, result: Result): Promise<TransactionResponse>;

  abstract grantRole(role: string, account: string): Promise<TransactionResponse>;
}
