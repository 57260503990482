import { Trans } from 'next-i18next';
import { useRouter } from 'next/router';
import type { VFC } from 'react';
import Link from '@components/routing/Link';
import NetworkBanner from '@components/web3/NetworkBanner';
import { useCloseMigrationAlertMutation } from '@graphql/client/generated/closeMigrationAlert.generated';
import useUser from '@hooks/useUser';
import { addressCafe } from '@lib/web3/constants/contracts';
import { DPS } from '@lib/web3/constants/tokens';
import useAVAXBalance from '@lib/web3/hooks/useAVAXBalance';
import useWallet from '@lib/web3/hooks/useWallet';
import useWatchAsset from '@lib/web3/hooks/useWatchAsset';
import useWeb3 from '@lib/web3/hooks/useWeb3';
import e from '@lib/web3/utils/e';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import formatAVAX from '@utils/format/formatAVAX';

const CAFE_BALANCE_THRESHOLD = e(17).mul(5); // 0.5 AVAX

const HeaderAlerts: VFC = () => {
  const { active, activate } = useWeb3();
  const { user } = useUser();
  const { mismatch } = useWallet();
  const cafeBalance = useAVAXBalance(addressCafe);
  const { pathname } = useRouter();
  const [closeMigrationAlert] = useCloseMigrationAlertMutation({
    optimisticResponse: {
      updateMe: user
        ? {
            ...user,
            state: {
              ...user?.state,
              closeMigrationAlert: true,
            },
          }
        : undefined,
    },
  });
  const watch = useWatchAsset();

  return (
    <>
      <NetworkBanner />

      {mismatch && (
        <Alert variant="filled" severity="error" className="lg:px-32 m-0">
          <Trans i18nKey="alert.wallet-mismatch">
            <AlertTitle>Wallet mismatch</AlertTitle>
            You are connected to a wallet address that is not registered in our database.
            <br />
            Please connect to the wallet address you registered with to continue. ({{ address: user?.wallet?.address }})
          </Trans>
        </Alert>
      )}

      {user && user.kyc.tier === 0 && user.investedUSD > 0 && pathname !== '/profile' && !mismatch && (
        <Alert severity="warning" className="lg:px-64 m-0">
          <Trans i18nKey="alert.missing-kyc">
            To be able to move your DPS tokens for token listing, please complete the KYC process by clicking{' '}
            <Link className="underline cursor-pointer text-white" raw href="/profile">
              here
            </Link>
            .
          </Trans>
        </Alert>
      )}

      {user && user.admin && cafeBalance && cafeBalance.lt(CAFE_BALANCE_THRESHOLD) && (
        <Alert severity="warning" className="lg:px-64 m-0" sx={{ color: 'white' }}>
          <Trans i18nKey="alert.low-avax">
            The wallet handling KYC verification is getting low in AVAX (currently {{ amount: formatAVAX(cafeBalance) }}
            ). Please refill it, or the KYC process could be blocked.
          </Trans>
        </Alert>
      )}

      {user && !user.state.closeMigrationAlert && (
        <Alert severity="info" className="lg:px-64" onClose={active ? () => void closeMigrationAlert() : undefined}>
          <Trans i18nKey="alert.avalanche-migration">
            Your DPS tokens have already been transferred on Avalanche! Click{' '}
            <span className="underline cursor-pointer" onClick={() => (active ? watch(DPS) : activate())}>
              here
            </span>{' '}
          </Trans>

          {active ? (
            <Trans i18nKey="alert.avalanche-migration-add-token">to add the token to MetaMask.</Trans>
          ) : (
            <Trans i18nKey="alert.avalanche-migration-connect">
              to connect to MetaMask. If MetaMask does not show up, please read our{' '}
              <Link
                className="text-white decoration-white"
                target="_blank"
                rel="noreferrer"
                href="/faq?q=connect-nothing-happens"
              >
                FAQ
              </Link>
              .
            </Trans>
          )}
        </Alert>
      )}
    </>
  );
};

export default HeaderAlerts;
