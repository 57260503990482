import type { UnsupportedChainIdError } from '@web3-react/core';

export default function isUnsupportedChainIdError(error: unknown): error is UnsupportedChainIdError {
  return (
    typeof error === 'object' &&
    error !== null &&
    'name' in error &&
    (error as { name: string }).name === 'UnsupportedChainIdError'
  );
}
