import type { NextPage } from 'next';
import { Trans } from 'next-i18next';
import { useRouter } from 'next/router';
import type { ComponentType, FC } from 'react';
import { useEffect } from 'react';
import Loader from '@components/ui/Loader';
import useJwt from '@hooks/useJwt';
import useUser from '@hooks/useUser';
import { JWT_STORAGE_KEY } from '@lib/constants';
import { isPlatformServer } from '@utils/platform';

/**
 * Requires the user to be logged before accessing to the page.
 * @param {NextPage<T>} Page The page component.
 * @param {string} redirectTo When the user is not logged, where he should be redirected (defaults to /auth/login)
 * @returns {React.ComponentType<T>}
 */
const withUserRequired = <T,>(Page: NextPage<T>, redirectTo = '/auth/login'): ComponentType<T> => {
  function WithUserRequired(props: T): ReturnType<FC<T>> {
    const router = useRouter();
    const isValid = useJwt();
    const { user, loading: loadingUser } = useUser(); // skip is already handled in the useUser() hook
    const loading = isPlatformServer() || loadingUser;

    useEffect(() => {
      if (!isValid) {
        // No valid JWT: redirect to login page
        localStorage.removeItem(JWT_STORAGE_KEY);
        void router.replace(redirectTo);
      }
    }, [isValid, router]);

    if (!user) {
      return <Loader loading={loading ? <Trans i18nKey="loading-your-profile">Loading your profile</Trans> : false} />;
    }

    return <Page {...props} />;
  }

  return WithUserRequired;
};

export default withUserRequired;
