import { useEffect } from 'react';
import useWeb3 from '@lib/web3/hooks/useWeb3';
import Event from '@lib/web3/lib/Event';

/**
 * Run a callback on mont and when the {@link Event.RELOAD} is dispatched by the connector.
 * @param {() => (void | Promise<void>)} effect The callback to run
 */
export default function useWeb3Effect(effect: () => void | Promise<void>) {
  const { error, connector } = useWeb3();

  useEffect(() => void effect(), [effect, error]);

  useEffect(() => {
    if (!connector) return;
    connector.on(Event.RELOAD, effect);

    return () => {
      connector.off(Event.RELOAD, effect);
    };
  }, [connector, effect]);
}
