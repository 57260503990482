import Head from 'next/head';
import type { FC, ReactNode } from 'react';
import Footer from '@components/structure/Footer';
import Header from '@components/structure/Header';
import CircularProgress from '@mui/material/CircularProgress';
import makeTitle from '@utils/makeTitle';

interface MainLayoutProps {
  /** The page title (equivalent to the <title> tag in the page <head>). */
  title: string;
  /** The page description (equivalent to the <meta name="description"> tag in the page <head>). */
  description?: string;
  /** If the page should display a centralized loader. Passing a ReactNode allow adding content boolean the MUI CircularProgress. */
  loading?: boolean | ReactNode;
}

const MainLayout: FC<MainLayoutProps> = ({ title, description, loading, children }) => (
  <>
    <Head>
      <title>{makeTitle(title)}</title>
      {description && <meta name="description" content={description} />}
    </Head>

    <div className="min-h-screen flex flex-col bg-gradient-to-br from-background to-neutral">
      <Header className="flex-shrink" />

      <main className="relative flex-grow container">
        {loading ? (
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
            <CircularProgress size={80} />
            {typeof loading === 'string' && <div className="mt-2">{loading}</div>}
          </div>
        ) : (
          children
        )}
      </main>

      <Footer className="flex-shrink mt-8" />
    </div>
  </>
);

export default MainLayout;
