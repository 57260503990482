import { addressDPS, addressSTC } from '@lib/web3/constants/contracts';
import type { WatchAssetParameters } from '@lib/web3/eip/EIP-747';

export type ERC20Token = Required<Omit<WatchAssetParameters['options'], 'image'>> &
  Pick<WatchAssetParameters['options'], 'image'>;

export const DPS: ERC20Token = {
  address: addressDPS,
  symbol: 'DPS',
  decimals: 18,
  image: 'https://static.deepsquare.io/tokens/dps.png',
};

export const STC: ERC20Token = {
  address: addressSTC,
  symbol: 'USDC',
  decimals: 6,
  image: 'https://static.deepsquare.io/tokens/usdc.png',
};
