import type { FC } from 'react';
import type { ComponentPropsWithoutRef } from 'react';
import Link from '@components/routing/Link';
import { CHAIN } from '@lib/web3/constants/chains';
import { DPS, STC } from '@lib/web3/constants/tokens';
import Token from '@lib/web3/lib/Token';

interface BlockChainExplorerProps extends Omit<ComponentPropsWithoutRef<'a'>, 'href' | 'target' | 'rel'> {
  address: string;
  token: Token;
}

export function getBlockChainUrl(address: string, token: Token) {
  const explorer = CHAIN.blockExplorerUrls?.[0] ?? '#';
  let href = explorer.replace(/\/+$/, '');

  if (explorer !== '#') {
    switch (token) {
      case Token.NATIVE:
      case Token.AVAX:
      case Token.ETH:
        href += `/address/${address}`;
        break;
      case Token.STC:
      case Token.USDTe:
      case Token.USDT:
        href += `/token/${STC.address}?a=${address}`;
        break;
      case Token.DPS:
        href += `/token/${DPS.address}?a=${address}`;
        break;
    }
  }

  return href;
}

const BlockChainExplorer: FC<BlockChainExplorerProps> = ({ address, token, ...props }) => {
  return <Link raw href={getBlockChainUrl(address, token)} {...props} />;
};

export default BlockChainExplorer;
