/**
 * Tokens mappings (name => symbol).
 */
enum Token {
  ETH = 'ETH',
  AVAX = 'AVAX',
  DPS = 'DPS',
  USDT = 'USDT',
  USDTe = 'USDT.e',

  // Aliases
  NATIVE = 'NATIVE',
  STC = 'STC',
}

export default Token;
