import { useForm } from 'react-hook-form';
import { AiOutlineUserSwitch } from 'react-icons/ai';
import { MdOutlineClose } from 'react-icons/md';
import { useKonami } from 'react-konami-code';
import type { VFC } from 'react';
import TextField from '@components/forms/fields/TextField';
import useImpersonate from '@hooks/useImpersonate';
import useLocalStorage from '@hooks/useLocalStorage';
import useUser from '@hooks/useUser';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

const DebugBar: VFC = () => {
  const { user } = useUser();
  const { impersonating, impersonate, stop } = useImpersonate();
  const [unlocked, setUnlocked] = useLocalStorage('impersonate.unlocked', false);
  const { control, handleSubmit } = useForm({
    defaultValues: { email: impersonating ?? '' },
  });

  // secret code to unlock the debug bar
  useKonami(
    () => {
      if (!user?.admin && !impersonating) return; // non-admin have no effect
      setUnlocked(true);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    {
      code: [67, 65, 84, 66, 79, 89],
    },
  );

  if (!unlocked) {
    return null;
  }

  const onSubmit = (data: { email: string }) => {
    impersonate(data.email);
  };

  const handleClose = () => {
    stop();
    setUnlocked(false);
  };

  return (
    <div className="bg-neutral">
      <div className="container flex justify-between py-4">
        <form onSubmit={handleSubmit(onSubmit)} className="flex gap-4 items-center">
          <TextField
            control={control}
            className="my-0"
            autoComplete="off"
            fullWidth={false}
            label="Impersonate"
            name="email"
            size="small"
            placeholder="example@email.com"
            InputProps={{
              endAdornment: (
                <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                  <AiOutlineUserSwitch />
                </IconButton>
              ),
            }}
          />

          {impersonating && (
            <div className="flex items-center gap-2">
              <div>
                Impersonating: {user?.email} - {user?.wallet?.address}
              </div>
              <Button onClick={stop}>Stop</Button>
            </div>
          )}
        </form>

        <div>
          <IconButton>
            <MdOutlineClose onClick={handleClose} />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default DebugBar;
