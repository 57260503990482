import type { Provider, TransactionResponse } from '@ethersproject/abstract-provider';
import type { Signer } from '@ethersproject/abstract-signer';
import type { BigNumber, BigNumberish } from '@ethersproject/bignumber';
import type { CallOverrides } from '@ethersproject/contracts';
import { BaseContract, Contract } from '@ethersproject/contracts';
import { addressSale } from '@lib/web3/constants/contracts';
import abi from '@lib/web3/contracts/Sale.abi.json';

export const SaleContract = Symbol('SaleContract');

export function createSale(provider?: Signer | Provider) {
  return new Contract(addressSale, abi, provider) as Sale;
}

export abstract class Sale extends BaseContract {
  abstract owner(): Promise<string>;

  /**
   * The DPS token contract being sold.
   */
  abstract DPS(): Promise<string>;

  /**
   * The stablecoin ERC20 contract.
   */
  abstract STC(): Promise<string>;

  /**
   * Is the Sale paused or not.
   */
  abstract isPaused(): Promise<boolean>;

  /**
   * The eligibility contract.
   */
  abstract eligibility(): Promise<string>;

  /**
   * How many cents costs a DPS (e.g., 40 means a single DPS token costs 0.40 STC).
   */
  abstract rate(): Promise<number>;

  /**
   * The minimum DPS purchase amount in stablecoin.
   */
  abstract minimumPurchaseSTC(): Promise<BigNumber>;

  /**
   * How many DPS tokens were sold during the sale.
   */
  abstract sold(): Promise<BigNumber>;

  /**
   * Get the remaining DPS tokens to sell.
   * @return The amount of DPS remaining in the sale.
   */
  abstract remaining(): Promise<BigNumber>;

  /**
   * Purchase DPS with AVAX native currency.
   * The invested amount will be msg.value.
   * @param {CallOverrides} overrides The ethers call overrides.
   */
  abstract purchaseDPSWithAVAX(overrides?: CallOverrides): Promise<TransactionResponse>;

  /**
   * Purchase DPS with stablecoin.
   * @param amountSTC The amount of stablecoin to invest.
   */
  abstract purchaseDPSWithSTC(amountSTC: BigNumberish): Promise<TransactionResponse>;

  /**
   * Modify isPaused boolean which controls whether it is possible to buy tokens or not.
   */
  abstract setPause(pause: boolean): Promise<TransactionResponse>;

  /**
   * Close the sale by sending the remaining tokens back to the owner and then renouncing ownership.
   */
  abstract close(): Promise<TransactionResponse>;
}
